import React from 'react';
import blissMock19 from '../assets/blissmock-19.webp';
// import VendorDiv from './Reusable/VendorDiv.js';
// import {
//   caterers,
//   decorators,
//   djs,
//   eventPlanners,
//   hotels,
//   photoVideo,
// } from './Reusable/vendorInfo.js';

const Vendors = (props) => {
  // Removes toast if it wasn't closed when user was on homepage
  // useEffect(() => {
  //   const pathname = window.location.pathname
  //   if (props.showToast && pathname !== '/') {
  //     props.setShowToast(false)
  //   }
  // })

  return (
    <div className='border-t border-t-blissGold space-y-2 md:space-y-3 mb-5'>
      {/* Header Image */}
      <div className='relative border-b border-b-blissGold'>
        <div className='carousel'>
          <div className='innerWrap'>
            <div className='carouselItem'>
              <img
                className='h-full w-full object-cover absolute inset-y-0 my-auto'
                src={blissMock19}
                alt='Group of people'
              />
            </div>
          </div>
        </div>
        {/* Title */}
        <div className='absolute w-full top-[39.8%] z-0 text-blissWhite font-workSans text-center text-4xl cursor-default xssm:text-5xl md:text-6xl'>
          <h1 className='font-light mx-auto'>Vendors</h1>
        </div>
      </div>
      <div className='pt-5 pb-3 lg:pt-7 lg:pb-5'>
        <p className='text-blissWhite font-workSans font-extralight text-center px-[2%] mx-auto w-11/12 xs:text-lg xs:w-10/12 sm:text-xl sm:w-8/12 lg:w-6/12  md:font-thin md:text-xl lg:text-2xl'>
          Explore a seamless event experience with our trusted network of
          top-tier vendors. From expert event planners and creative decorators
          to nearby hotel accommodations, we offer a full range of customizable
          options to make your event truly unique. Contact us today to discuss
          how we can bring your vision to life!
        </p>
      </div>
      <div className='border-t border-t-blissGold pt-5 pb-3 lg:pt-7 lg:pb-5'>
        <div className='grid '>
          <h1 className='text-blissWhite text-3xl text-center font-raleway font-thin sm:text-4xl md:text-5xl'>
            Book Your Event
          </h1>
          <p className='text-blissWhite font-workSans font-extralight text-center mx-auto w-8/12 xs:text-base xs:w-6/12 sm:text-xl sm:w-5/12 md:font-thin md:text-2xl md:w-4/12 lg:text-3xl'>
            Let us and our partnered vendors help you host the perfect event.
          </p>
        </div>
        <div className='flex flex-col items-center justify-center'>
          <a
            href='https://www.honeybook.com/widget/the_bliss_space_237074/cf_id/635ddc1551bb8b031c8a6ec4'
            className='text-sm text-blissWhite font-workSans bg-blissGold border-[0.5px] border-blissGold px-2 py-1 rounded font-light drop-shadow-md block mx-auto hover:text-blissGold hover:bg-blissBlack hover:scale-110 transition-all ease-in-out duration-[400ms] md:font-extralight md:text-lg lg:text-xl xl:text-2xl'
            target='_blank'
            rel='noreferrer'
          >
            Start Planning
          </a>
        </div>
      </div>
    </div>
  );
};

export default Vendors;

// div for book your event py-2 space-y-3 lg:space-y-5 lg:py-5
