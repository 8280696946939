import React from 'react';
import TOS from '../assets/BlissEventSpaceTermsAndConditions.pdf';
import privacyPolicy from '../assets/BlissEventSpacePrivacyPolicy.pdf';
import { FaInstagram, FaTiktok } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';
import { MdFacebook, MdOutlineMail } from 'react-icons/md';

const Footer = () => {
  return (
    <div
      id='footer'
      className='border-t border-t-blissGold w-screen text-blissGold font-raleway font-extralight py-4 '
    >
      <div className='space-y-2'>
        {/* Social Media Links */}
        <div className='flex items-center justify-center space-x-3'>
          <a href='mailto:theblissspacellc@gmail.com'>
            <MdOutlineMail className='text-[34px]' />
          </a>
          <a
            href='https://instagram.com/blisseventspace?utm_medium=copy_link'
            target='_blank'
            rel='noreferrer'
          >
            <FaInstagram className='text-3xl' />
          </a>
          <a
            href='https://www.facebook.com/blisseventspace'
            target='_blank'
            rel='noreferrer'
          >
            <MdFacebook className='text-3xl' />
          </a>
          <a
            href='https://twitter.com/BlissSpaceIndy'
            target='_blank'
            rel='noreferrer'
          >
            <FaXTwitter className='text-2xl' />
          </a>
          <a
            href='https://www.tiktok.com/@blissspaceindy'
            target='_blank'
            rel='noreferrer'
          >
            <FaTiktok className='text-2xl' />
          </a>
        </div>
        {/* Copyright */}
        <div className='text-center text-[18px]'>
          <p>© Bliss Event Space LLC 2024</p>
        </div>
        {/** TOS & Privacy Policy **/}
        <div className='flex text-blissWhite items-center justify-center space-x-2 text-[13px]'>
          <a
            href={TOS}
            target='_blank'
            rel='noreferrer'
            className='footerLink'
          >
            Terms of Service
          </a>
          <div className='h-[1rem] w-[1px] bg-blissGold'></div>
          <a
            href={privacyPolicy}
            target='_blank'
            rel='noreferrer'
            className='footerLink'
          >
            Privacy Policy
          </a>
        </div>
        <div className='flex text-center text-[13px] text-blissWhite w-fit mx-auto space-x-1'>
          <p>Built by</p>
          <a
            href='https://gabrielleford.dev/'
            className='underline decoration-1 hover:text-blissWhite transition-colors duration-300 ease-in-out'
            target='_blank'
            rel='noreferrer'
          >
            Gabrielle Ford
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
