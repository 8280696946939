import React, { useEffect, useState } from 'react';
import blissMock39 from '../assets/blissmock-39.webp';
import { galleryImgs } from './Reusable/carouselImgs';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
// import { IoIosArrowDown } from 'react-icons/io'

const Gallery = (props) => {
  const [open, setOpen] = useState(false);
  const [screenSize, setScreenSize] = useState('mobile');
  // console.log(open)

  // const galleryList = ['Baby Showers', 'Birthday Celebrations', 'Corporate Events', 'Holidays', 'Receptions', 'Religious Gatherings', 'Weddings', 'Vendor Fairs']

  // Removes toast if it wasn't closed when user was on homepage
  // useEffect(() => {
  //   const pathname = window.location.pathname
  //   if (props.showToast && pathname !== '/') {
  //     props.setShowToast(false)
  //   }
  // })

  useEffect(() => {
    props.desktopResolution
      ? setScreenSize('desktop')
      : props.largeMobile
      ? setScreenSize('large mobile')
      : setScreenSize('mobile');
  }, [screenSize, props.desktopResolution, props.largeMobile]);

  const responsive = {
    0: {
      items: 1,
    },
    510: {
      items: 2,
    },
    1024: {
      items: 3,
    },
  };

  const imgs = galleryImgs.map((image, index) => {
    return (
      <img
        className={image.className}
        src={image.src}
        alt={image.alt}
      />
    );
  });

  return (
    <div
      onClick={() => open && setOpen(false)}
      className='static z-20'
    >
      {/* Header Image */}
      <div className='relative'>
        <div className='carousel'>
          <div className='innerWrap'>
            <div className='carouselItem'>
              <img
                className='h-full w-full object-cover absolute inset-y-0 my-auto'
                src={blissMock39}
                alt='Group of people'
              />
            </div>
          </div>
        </div>
        {/* Title */}
        <div className='absolute w-full top-[39.8%] z-0 text-blissWhite font-workSans text-center text-4xl cursor-default xssm:text-5xl md:text-6xl'>
          <h1 className='font-light mx-auto'>Gallery</h1>
        </div>
      </div>
      {/* Description */}
      <div className='border-y border-y-blissGold pt-5 pb-3 lg:pt-7 lg:pb-5'>
        <p className='text-blissWhite font-workSans font-extralight text-center px-[2%] mx-auto text-lg w-11/12 xxs:text-2xl xs:w-10/12 sm:w-8/12 md:font-thin md:text-3xl md:w-7/12 lg:text-4xl lg:w-5/12 mb-2.5'>
          Explore the possibilities
          <br />
          of your next event.
        </p>
        {/* <div className='flex flex-col items-center justify-center'>
          <a href='https://instagram.com/blisseventspace?utm_medium=copy_link' className='text-sm text-blissWhite font-workSans bg-blissGold border-[0.5px] border-blissGold px-2 py-1 rounded font-light drop-shadow-md block mx-auto hover:text-blissGold hover:bg-blissBlack hover:scale-110 transition-all ease-in-out duration-[400ms] md:font-extralight md:text-lg lg:text-xl xl:text-2xl' target='_blank' rel='noreferrer'>Follow Us</a>
        </div> */}
      </div>
      {/* Filter */}
      {/* <div className='relative w-full px-4 cursor-default'>
        <div className='absolute  bottom-0 z-10'>
          <p onClick={() => setOpen(!open)} className='relative z-0 flex items-center font-workSans text-blissWhite'>Jump to <IoIosArrowDown/></p>
        </div>
      </div>
      <div className={!open ? 
      'bg-blissWhite max-h-0 py-0 transition-all duration-[0.4s] ease-in-out overflow-hidden'
      : 'bg-blissWhite max-h-[400px] py-[0.3rem] transition-all duration-[0.4s] ease-in-out'}>
        {galleryList.map((eventTitle) => {
          const lastEvent = galleryList[galleryList.length - 1]
          return(
            <>
            {eventTitle !== lastEvent ? 
            <div key={eventTitle} className={open ?
            'px-7 w-full border-b border-blissDarkGray opacity-100 transition-all duration-[0.4s] ease-in-out' 
            : 'px-7 w-full border-b border-blissDarkGray opacity-0 transition-all duration-[0.4s] ease-in-out'}>
              <p>{eventTitle}</p>
            </div> :
            <div key={eventTitle} className={open ?
            'px-7 w-full opacity-100 transition-all duration-[0.4s] ease-in-out'
            : 'px-7 w-full opacity-0 transition-all duration-[0.4s] ease-in-out'}>
              <p>{eventTitle}</p>
            </div>
            }
            </>
          )
        })}
      </div> */}
      {/* Carousel */}
      <AliceCarousel
        mouseTracking
        infinite
        items={imgs}
        responsive={responsive}
        disableDotsControls
      />
      <div className='border-t border-t-blissGold py-2 mb-5 -mt-[1.62rem] space-y-3 lg:space-y-5 lg:py-5'>
        <h1 className='text-blissWhite text-2xl text-center font-raleway font-thin sm:text-4xl md:text-5xl'>
          Book Your Event
        </h1>
        <p className='text-blissWhite font-workSans font-extralight text-center mx-auto w-8/12 xs:text-base xs:w-6/12 sm:text-xl sm:w-5/12 md:font-thin md:text-2xl md:w-4/12 lg:text-3xl'>
          Bring the vision of <br /> your event to life.
        </p>
        <div className='flex flex-col items-center justify-center'>
          <a
            href='https://www.honeybook.com/widget/the_bliss_space_237074/cf_id/635ddc1551bb8b031c8a6ec4'
            className='text-sm text-blissWhite font-workSans bg-blissGold border-[0.5px] border-blissGold px-2 py-1 rounded font-light drop-shadow-md block mx-auto hover:text-blissGold hover:bg-blissBlack hover:scale-110 transition-all ease-in-out duration-[400ms] md:font-extralight md:text-lg lg:text-xl xl:text-2xl'
            target='_blank'
            rel='noreferrer'
          >
            Start Planning
          </a>
        </div>
      </div>
    </div>
  );
};

export default Gallery;

// ** Title styling
// {!open ?
//   'absolute w-full top-[39.8%] z-0 text-blissWhite font-workSans text-center text-4xl cursor-default'
//   : 'absolute w-full top-[21%] z-0 text-blissWhite font-workSans text-center text-4xl cursor-default'}

// ** Old Gallery Page
/* <div className='border-t border-t-blissGold py-2 mt- md:space md:py-5'>
      <h1 className='text-blissWhite text-2xl text-center font-raleway font-thin xs:text-3xl sm:text-4xl md:text-5xl mb-4'>Gallery</h1>
      <p className='text-blissWhite font-workSans font-extralight text-center px-[2%] mx-auto w-11/12 xs:text-lg xs:w-10/12 sm:text-xl sm:w-8/12 md:font-thin md:text-2xl md:w-7/12 lg:text-3xl lg:w-5/12 mb-1'>
        Coming soon
      </p>
      <p className='text-blissWhite font-workSans font-extralight text-center text-sm px-[2%] mx-auto w-11/12 xs:text-sm xs:w-10/12 sm:text-base sm:w-8/12 md:font-thin md:text-lg md:w-7/12 lg:text-xl lg:w-5/12 mb-2.5'>
        For now, follow us on<br/> 
        Instagram for updates.
      </p>
      <div className='flex flex-col items-center justify-center'>
        <a href='https://instagram.com/blisseventspace?utm_medium=copy_link' className='text-sm text-blissWhite font-workSans bg-blissGold border-[0.5px] border-blissGold px-2 py-1 rounded font-light drop-shadow-md block mx-auto hover:text-blissGold hover:bg-blissBlack hover:scale-110 transition-all ease-in-out duration-[400ms] md:font-extralight md:text-lg lg:text-xl xl:text-2xl' target='_blank' rel='noreferrer'>Follow Us</a>
      </div>
    </div> */
